import { gql, useMutation } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { SPECIAL_EVENTS_QUERY } from './specialEventsQuery';

const UPDATE_SPECIAL_EVENT_MUTATION = gql`
  mutation UpdateSpecialEvent(
    $category: EventCategory
    $description: String
    $end: Date
    $impact: EventImpact
    $name: String
    $portfolioId: Float!
    $specialEventId: Float!
    $start: Date
    $teamId: Float!
  ) {
    updateSpecialEvent(
      category: $category
      description: $description
      end: $end
      impact: $impact
      name: $name
      portfolioId: $portfolioId
      specialEventId: $specialEventId
      start: $start
      teamId: $teamId
    ) {
      updatedSpecialEvent {
        specialEventId
      }
    }
  }
`;

type Options = {
  portfolioId: number;
};

function useUpdateSpecialEventMutation({ portfolioId }: Options) {
  const { teamId } = useTeam();

  return useMutation(UPDATE_SPECIAL_EVENT_MUTATION, {
    refetchQueries: [
      {
        query: SPECIAL_EVENTS_QUERY,
        variables: { teamId, portfolioId },
      },
    ],
  });
}

export { UPDATE_SPECIAL_EVENT_MUTATION, useUpdateSpecialEventMutation };
