import { gql, useQuery } from '@apollo/client';

import { useTeam } from '../../context/TeamProvider';

export const OPTIMIZATION_SPECIAL_EVENTS = gql`
  query OptimizationSpecialEvents($teamId: Int!, $portfolioId: Int!, $optimizationId: Int!) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      optimization(optimizationId: $optimizationId) {
        optimizationId
        specialEvents {
          name
          start
          end
        }
      }
    }
  }
`;

type Props = {
  portfolioId: number;
  optimizationId: number;
};

function useOptimizationSpecialEvents({ portfolioId, optimizationId }: Props) {
  const { teamId } = useTeam();
  return useQuery(OPTIMIZATION_SPECIAL_EVENTS, {
    variables: {
      teamId,
      portfolioId,
      optimizationId,
    },
  });
}

export { useOptimizationSpecialEvents };
