import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { capitalize } from 'lodash';
import styled from 'styled-components';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { NexoyaOptimizationStatus, NexoyaOptimizationTaskStatus, NexoyaOptimizationV2 } from '../../types';

import { useTeam } from '../../context/TeamProvider';
import { useActiveOptimization } from '../../graphql/optimization/queryActiveOptimization';
import { OPTIMIZATION_LIST } from '../../graphql/optimization/queryOptimizationList';

import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';
import { format } from '../../utils/dates';

import MenuList from '../../components/ArrayMenuList/ArrayMenuList';
import Button from '../../components/Button';
import ButtonAdornment from '../../components/ButtonAdornment';
import { useMenu } from '../../components/Menu';
import MenuItem from '../../components/MenuItem';
import MultipleSwitch from '../../components/MultipleSwitchFluid';
import Panel from '../../components/Panel';
import { useSidePanelState } from '../../components/SidePanel';
import SvgBudgetAutomaticDefault from '../../components/icons/BudgetAutomaticDefault';
import SvgCaretDown from '../../components/icons/CaretDown';
import SvgGauge from '../../components/icons/Gauge';
import SvgNexoyaLogo from '../../components/icons/NexoyaLogo';
import { OptimizationView } from './components/OptimizationView';
import { OptimizationProposal } from './components/OptimizationProposal';
import ErrorMessage from 'components/ErrorMessage';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import { SidePanelContainerStyled } from './styles/OptimizationProposal';

import NoDataFound from './NoDataFound';
import { useUserQuery } from '../../graphql/user/queryUser';

type Props = {
  portfolioId: number;
};

const HeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  min-height: 40px;
  .NEXYHelpCenter {
    margin-left: auto;
  }
`;

const LoadingWrapStyled = styled.div`
  padding-top: 20px;
  height: 100vh;

  & > div {
    margin-bottom: 15px;
    background: #f4f6f7;
    height: 40px;

    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(2) {
      opacity: 0.75;
    }
    &:nth-child(3) {
      opacity: 0.5;
    }
    &:nth-child(4) {
      opacity: 0.25;
    }
  }
`;

export const OPTIMIZATION_STATUSES = {
  RUNNING: 'RUNNING',
  APPLIED: 'APPLIED',
  DISCARDED: 'DISCARDED',
  CANCELLED: 'CANCELLED',
};

export const OPTIMIZATION_SECTIONS = [
  {
    id: OPTIMIZATION_STATUSES.RUNNING,
    text: 'In progress',
  },
  {
    id: OPTIMIZATION_STATUSES.APPLIED,
    text: 'Applied',
  },
  {
    id: OPTIMIZATION_STATUSES.DISCARDED,
    text: 'Discarded',
  },
];

const OPTIMIZATIONS_PAGE_SIZE = 10;

function Optimize({ portfolioId }: Props) {
  const [queryParams, setQueryParams] = useQueryParams({
    optimization_id: NumberParam,
    optimizationSwitch: StringParam,
    expandedOptimizationView: BooleanParam,
  });
  const { teamId } = useTeam();
  const { anchorEl, open, toggleMenu, closeMenu } = useMenu();
  const {
    isOpen: isExpandedViewOpen,
    toggleSidePanel: toggleExpandedView,
    openSidePanel: openExpandedView,
    closeSidePanel: closeExpandedView,
  } = useSidePanelState({
    initialState: queryParams.expandedOptimizationView,
  });
  const { data: userData } = useUserQuery();

  const [isViewLoading, setIsViewLoading] = useState(true);
  const [lastNodeCursor, setLastNodeCursor] = useState<string>();
  const [optimizationsToShow, setOptimizationsToShow] = useState<NexoyaOptimizationV2[]>([]);
  const [selectedOptimization, setSelectedOptimization] = useState<NexoyaOptimizationV2>();
  const [loadOptimizationList, { loading, error, data: optimizationListData }] = useLazyQuery(OPTIMIZATION_LIST);
  const { data: activeOptimizationData, loading: activeOptimizationLoading } = useActiveOptimization({
    portfolioId,
  });

  const queryParamOptimizationId = queryParams.optimization_id;
  const activeOptimization: NexoyaOptimizationV2 = activeOptimizationData?.portfolioV2.activeOptimization;
  const visibleToAllUsers = !activeOptimization?.onlyVisibleToSupportUsers;
  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  const pageInfo = optimizationListData?.portfolioV2?.optimizations?.pageInfo;
  const activeOptimizationSwitch = queryParams.optimizationSwitch || NexoyaOptimizationStatus.Running;

  useEffect(() => {
    if (queryParams.expandedOptimizationView) {
      openExpandedView();
    } else {
      closeExpandedView();
    }
  }, [queryParams.expandedOptimizationView]);

  useEffect(() => {
    if (!portfolioId || !teamId) return;
    setIsViewLoading(true);
    switch (activeOptimizationSwitch) {
      case OPTIMIZATION_STATUSES.RUNNING:
        if (activeOptimization?.tasks.PROPOSAL_WAITING === NexoyaOptimizationTaskStatus.Running) {
          setOptimizationsToShow([activeOptimization]);
        } else {
          setOptimizationsToShow([]);
        }
        setIsViewLoading(false);
        break;
      case OPTIMIZATION_STATUSES.APPLIED:
        loadOptimizationList({
          variables: {
            teamId,
            portfolioId,
            status: OPTIMIZATION_STATUSES.APPLIED,
            first: OPTIMIZATIONS_PAGE_SIZE,
          },
        }).then(({ data }) => {
          const optiNodes = data?.portfolioV2?.optimizations?.edges?.map((edge) => edge.node);
          setOptimizationsToShow(optiNodes);
          setLastNodeCursor(data?.portfolioV2?.optimizations?.pageInfo?.endCursor);
          setIsViewLoading(false);
        });

        break;
      case OPTIMIZATION_STATUSES.DISCARDED:
        loadOptimizationList({
          variables: {
            teamId,
            portfolioId,
            status: OPTIMIZATION_STATUSES.DISCARDED,
            first: OPTIMIZATIONS_PAGE_SIZE,
          },
        }).then(({ data }) => {
          const optiNodes = data?.portfolioV2?.optimizations?.edges?.map((edge) => edge.node);
          setOptimizationsToShow(optiNodes);
          setLastNodeCursor(data?.portfolioV2?.optimizations?.pageInfo?.endCursor);
          setIsViewLoading(false);
        });
        break;
    }
  }, [activeOptimizationSwitch, activeOptimization]);

  useEffect(() => {
    if (isViewLoading) return;

    const selectedOpti = optimizationsToShow?.find(
      (optimization) => optimization.optimizationId === queryParamOptimizationId,
    );
    setSelectedOptimization(selectedOpti);

    // if the opti list is empty, remove the query param
    if (!optimizationsToShow?.length && !!queryParamOptimizationId) {
      setQueryParams({
        optimization_id: undefined,
      });
    }

    // if the opti cannot be found, preselect the first one
    if (!selectedOpti && optimizationsToShow?.length) {
      setQueryParams({
        optimization_id: optimizationsToShow[0]?.optimizationId,
      });
    }
  }, [optimizationsToShow, queryParamOptimizationId, isViewLoading]);

  const resetState = () => {
    setOptimizationsToShow([]);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && pageInfo?.hasNextPage) {
      loadOptimizationList({
        variables: {
          teamId,
          portfolioId,
          status: activeOptimizationSwitch,
          first: OPTIMIZATIONS_PAGE_SIZE,
          after: lastNodeCursor,
        },
      }).then(({ data }) => {
        const optimizations = data?.portfolioV2?.optimizations?.edges?.map((edge) => edge.node);
        setOptimizationsToShow((prevState) => [...prevState, ...optimizations]);
        setLastNodeCursor(data?.portfolioV2?.optimizations?.pageInfo?.endCursor);
      });
    }
  };

  const onSelectOptimization = (optimizationId: number | undefined) => {
    setQueryParams({
      optimization_id: optimizationId,
    });
  };

  // If visibleToAllUsers is false, we should render the NoData for all users except for support users
  const shouldRenderNoData =
    !activeOptimizationLoading &&
    activeOptimizationSwitch === OPTIMIZATION_STATUSES.RUNNING &&
    ((!visibleToAllUsers && !isSupportUser) || !activeOptimization);

  // If visibleToAllUsers is true, we should render the optimization proposal, otherwise we only render it for support users
  const shouldRenderInProgressOptiProposal =
    activeOptimizationSwitch === OPTIMIZATION_STATUSES.RUNNING &&
    activeOptimization &&
    (visibleToAllUsers || isSupportUser);

  return (
    <>
      <HeaderWrapperStyled>
        <MultipleSwitch
          sections={OPTIMIZATION_SECTIONS}
          initial={activeOptimizationSwitch}
          current={activeOptimizationSwitch}
          onToggle={(selectedOption) => {
            setQueryParams({
              optimizationSwitch: selectedOption,
            });
            track(EVENT.OPTIMIZE_SWITCH_STATUS(OPTIMIZATION_SECTIONS.find((os) => os.id === selectedOption)?.text));
          }}
        />
        {activeOptimizationSwitch !== OPTIMIZATION_STATUSES.RUNNING && optimizationsToShow?.length ? (
          <Button
            active={open}
            variant="contained"
            color="secondary"
            flat
            type="button"
            onClick={() => toggleMenu()}
            style={{ color: '#2A2A32', boxShadow: '#eaeaea 0px 0px 0px 1px' }}
            ref={anchorEl}
            startAdornment={
              <ButtonAdornment position="start">
                <SvgNexoyaLogo />
              </ButtonAdornment>
            }
            endAdornment={
              <ButtonAdornment position="end">
                <SvgCaretDown
                  style={{
                    transform: `rotate(${open ? '180' : '0'}deg)`,
                    fill: '#A6A7B5',
                  }}
                />
              </ButtonAdornment>
            }
          >
            {format(selectedOptimization?.start, 'MMM DD', true)} -{' '}
            {format(selectedOptimization?.end, 'MMM DD YYYY', true)}
          </Button>
        ) : (
          <div />
        )}

        <Panel
          open={open}
          anchorEl={anchorEl.current}
          onScroll={handleScroll}
          onClose={() => closeMenu()}
          placement="bottom"
        >
          <MenuList color="dark">
            {optimizationsToShow?.map((optimization) => (
              <MenuItem
                key={optimization.optimizationId}
                onClick={() => {
                  onSelectOptimization(optimization.optimizationId);
                  toggleMenu();
                }}
              >
                {format(optimization.start, 'MMM DD', true)} - {format(optimization.end, 'MMM DD YYYY', true)} (
                {capitalize(selectedOptimization?.status)})
              </MenuItem>
            ))}
          </MenuList>
        </Panel>
      </HeaderWrapperStyled>

      {shouldRenderNoData ? (
        <NoDataFound
          icon={<SvgGauge />}
          title="No optimization in progress"
          subtitle="Once you will launch an optimization, you will be able to track the progress here."
        />
      ) : null}

      {!loading && activeOptimizationSwitch === OPTIMIZATION_STATUSES.APPLIED && optimizationsToShow?.length === 0 ? (
        <NoDataFound
          icon={<SvgBudgetAutomaticDefault />}
          title="No optimization applied"
          subtitle="Once you will apply the optimization proposal, you will see the history of the performance here."
          cta={
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                setQueryParams({
                  optimizationSwitch: OPTIMIZATION_STATUSES.RUNNING,
                })
              }
            >
              See pending optimizations
            </Button>
          }
        />
      ) : null}

      {!loading && activeOptimizationSwitch === OPTIMIZATION_STATUSES.DISCARDED && optimizationsToShow?.length === 0 ? (
        <NoDataFound
          icon={<SvgGauge />}
          title="No optimization discarded"
          subtitle="Once you will discard an optimization proposal, you will see the history of the performance here."
          cta={
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                setQueryParams({
                  optimizationSwitch: OPTIMIZATION_STATUSES.RUNNING,
                })
              }
            >
              See pending optimizations
            </Button>
          }
        />
      ) : null}

      {shouldRenderInProgressOptiProposal ? (
        <OptimizationView optimization={activeOptimization} portfolioId={portfolioId} resetState={resetState} />
      ) : null}

      {activeOptimizationSwitch !== OPTIMIZATION_STATUSES.RUNNING && selectedOptimization ? (
        <OptimizationView optimization={selectedOptimization} portfolioId={portfolioId} resetState={resetState} />
      ) : null}

      <SidePanelContainerStyled
        isOpen={isExpandedViewOpen}
        onClose={() => {
          setQueryParams({ expandedOptimizationView: false });
          toggleExpandedView();
        }}
        paperProps={{
          style: {
            width: '95%',
            height: '95%',
            bottom: 0,
            right: 'unset',
            borderRadius: '12px 12px 0 0',
          },
        }}
      >
        {selectedOptimization && queryParams.expandedOptimizationView ? (
          <OptimizationProposal
            optimizationStatus={selectedOptimization.status}
            optimizationId={selectedOptimization.optimizationId}
            portfolioId={portfolioId}
            resetState={resetState}
            onBudgetApplied={() => {
              // addItem({
              //   optimizationId: optimization.optimizationId,
              //   dateApplied: format(date, 'DD MMM YYYY'),
              // });
            }}
          />
        ) : null}
      </SidePanelContainerStyled>

      {loading || activeOptimizationLoading ? (
        <LoadingWrapStyled>
          <LoadingPlaceholder />
          <LoadingPlaceholder />
          <LoadingPlaceholder />
          <LoadingPlaceholder />
        </LoadingWrapStyled>
      ) : null}

      {error ? <ErrorMessage error={error} /> : null}
    </>
  );
}

export default Optimize;
