import React from 'react';

import { DateSelector } from '../../../../components/DateSelector';
import FormGroup from '../../../../components/Form/FormGroup';
import TextField from '../../../../components/TextField';
import Typography from '../../../../components/Typography';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import useSpecialEventsStore from '../../../../store/special-events';
import { Info } from 'lucide-react';
import Tooltip from '../../../../components/Tooltip';
import { NexoyaEventCategory, NexoyaEventImpact } from '../../../../types';
import { getCategoryInfo } from '../../../../utils/specialEvents';
import dayjs from 'dayjs';

export const SpecialEventDetails = () => {
  const { newSpecialEvent, setNewSpecialEvent } = useSpecialEventsStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSpecialEvent({ [name]: value });
  };

  const categoryOptions = Object.entries(NexoyaEventCategory).map(([_, value]) => ({
    value,
    label: getCategoryInfo(value)?.title,
  }));

  const impactOptions = [
    { value: 'SMALL', label: 'Small impact' },
    { value: 'LARGE', label: 'Large impact' },
  ];

  return (
    <div className="flex max-w-96 flex-col gap-10 py-8">
      <FormGroup className="!m-0">
        <div className="mb-2 text-lg text-neutral-900">Event name</div>
        <TextField
          id="name"
          name="name"
          value={newSpecialEvent?.name}
          onChange={handleChange}
          placeholder="What is the event"
        />
      </FormGroup>

      <FormGroup className="!m-0">
        <div className="mb-2 text-lg font-normal text-neutral-500">Description (optional)</div>
        <TextField
          id="description"
          name="description"
          value={newSpecialEvent?.description}
          onChange={handleChange}
          placeholder="Add some additional information or something notable about the event."
          multiline
          rows={3}
        />
      </FormGroup>

      <FormGroup className="!m-0">
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          Timeframe
        </Typography>
        <DateSelector
          dateFrom={dayjs(newSpecialEvent?.start)?.toDate() || new Date()}
          dateTo={dayjs(newSpecialEvent?.end)?.toDate() || new Date()}
          disableAfterDate={null}
          onDateChange={(dateRange) => {
            setNewSpecialEvent({
              start: dateRange.from,
              end: dateRange.to,
            });
          }}
          style={{ width: '100%' }}
        />
      </FormGroup>

      <FormGroup className="!m-0">
        <div className="mb-2 text-lg text-neutral-900">Category</div>
        <Select
          value={newSpecialEvent?.category || undefined}
          onValueChange={(value) => setNewSpecialEvent({ category: value as NexoyaEventCategory })}
        >
          <SelectTrigger className="w-full border-[#E3E3E8] bg-transparent p-2">
            <SelectValue placeholder="What kind of event is it?" />
          </SelectTrigger>
          <SelectContent className="w-full">
            {categoryOptions.map((option) => (
              <div key={option.value} className="flex items-center">
                <SelectItem value={option.value}>{getCategoryInfo(option.value)?.title}</SelectItem>
                <Tooltip
                  popperProps={{
                    style: {
                      zIndex: 3500,
                    },
                  }}
                  style={{
                    maxWidth: 500,
                    wordBreak: 'break-word',
                  }}
                  placement="right"
                  variant="dark"
                  content={getCategoryInfo(option.value)?.description}
                >
                  <div className="px-2">
                    <Info className="h-4 w-4" />
                  </div>
                </Tooltip>
              </div>
            ))}
          </SelectContent>
        </Select>
      </FormGroup>

      <FormGroup className="!m-0">
        <div className="mb-2 text-lg text-neutral-900">Impact</div>
        <Select
          value={newSpecialEvent?.impact || undefined}
          onValueChange={(value) => setNewSpecialEvent({ impact: value as NexoyaEventImpact })}
        >
          <SelectTrigger className="w-full border-[#E3E3E8] bg-transparent p-2">
            <SelectValue placeholder="How impactful is the event?" />
          </SelectTrigger>
          <SelectContent>
            {impactOptions.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormGroup>
    </div>
  );
};
