import React, { useEffect, useState } from 'react';
import SidePanel, { SidePanelActions, SidePanelContent } from '../../../../components/SidePanel';
import { StepperWrapper, StepWrapper } from '../../../portfolios/CreatePortfolio';
import VerticalStepper from '../../../../components/VerticalStepper';
import ButtonAsync from '../../../../components/ButtonAsync';
import { MANUAL_SPECIAL_EVENT_CREATION_STEPS } from '../../../../configs/specialEvents';
import { useStepper } from '../../../../components/Stepper';
import { SpecialEventDetails } from './SpecialEventDetails';
import { SpecialEventAssignContents } from './SpecialEventAssignContents';
import useSpecialEventsStore from '../../../../store/special-events';
import { toast } from 'sonner';
import Button from '../../../../components/Button';
import { useCreateSpecialEventMutation } from '../../../../graphql/specialEvents/mutationCreateSpecialEvent';
import { useUpdateSpecialEventMutation } from '../../../../graphql/specialEvents/mutationUpdateSpecialEvent';
import usePortfolioMetaStore from '../../../../store/portfolio-meta';
import dayjs from 'dayjs';
import { useAssignContentsToSpecialEventMutation } from '../../../../graphql/specialEvents/mutationAssignContentsToSpecialEvent';
import { useFilteredContentsStore } from '../../../../store/filter-contents';
import { useTeam } from '../../../../context/TeamProvider';
import { useContentRulesStore } from '../../../../store/content-rules';
import { isEmpty } from 'lodash';

export const ManuallyCreateSpecialEvent = ({ isOpen, onClose, specialEventToEdit }) => {
  const {
    portfolioMeta: { portfolioId },
  } = usePortfolioMetaStore();

  const { teamId } = useTeam();

  const [specialEventId, setSpecialEventId] = useState<number | null>(null);
  const [includesAllContents, setIncludesAllContents] = useState<boolean>(null);

  const { specialEvents, newSpecialEvent, setNewSpecialEvent, setSpecialEvents, resetNewSpecialEvent } =
    useSpecialEventsStore();
  const { selectedContentIds } = useFilteredContentsStore();
  const { selectedContentRules, resetSelectedContentRules } = useContentRulesStore();

  const { step, nextStep, previousStep, resetStep } = useStepper({
    initialValue: 1,
    end: MANUAL_SPECIAL_EVENT_CREATION_STEPS.length,
  });

  const [createSpecialEvent, { loading: loadingCreate }] = useCreateSpecialEventMutation({ portfolioId });
  const [updateSpecialEvent, { loading: loadingUpdate }] = useUpdateSpecialEventMutation({ portfolioId });
  const [assignContents, { loading: loadingAssignContents }] = useAssignContentsToSpecialEventMutation({ portfolioId });

  const isEditMode = !isEmpty(specialEventToEdit);

  useEffect(() => {
    if (isEditMode && specialEventToEdit) {
      setNewSpecialEvent({
        name: specialEventToEdit.name,
        description: specialEventToEdit.description,
        start: specialEventToEdit.start,
        end: specialEventToEdit.end,
        category: specialEventToEdit.category,
        impact: specialEventToEdit.impact,
      });
      setSpecialEventId(specialEventToEdit.specialEventId);
    } else {
      resetNewSpecialEvent();
    }
  }, [isEditMode, specialEventToEdit, setNewSpecialEvent, resetNewSpecialEvent]);

  const handleSaveOrUpdateEvent = () => {
    const baseVariables = {
      teamId,
      portfolioId,
    };

    if (specialEventToEdit) {
      // Only include fields that have changed
      const changedFields = {};

      if (newSpecialEvent.name !== specialEventToEdit.name) {
        changedFields['name'] = newSpecialEvent.name;
      }
      if (newSpecialEvent.description !== specialEventToEdit.description) {
        changedFields['description'] = newSpecialEvent.description;
      }
      if (newSpecialEvent.start !== specialEventToEdit.start) {
        changedFields['start'] = dayjs(newSpecialEvent.start).endOf('day').toISOString().slice(0, 10);
      }
      if (newSpecialEvent.end !== specialEventToEdit.end) {
        changedFields['end'] = dayjs(newSpecialEvent.end).endOf('day').toISOString().slice(0, 10);
      }
      if (newSpecialEvent.category !== specialEventToEdit.category) {
        changedFields['category'] = newSpecialEvent.category;
      }
      if (newSpecialEvent.impact !== specialEventToEdit.impact) {
        changedFields['impact'] = newSpecialEvent.impact;
      }

      const variables = {
        ...baseVariables,
        specialEventId,
        ...changedFields,
      };

      updateSpecialEvent({ variables })
        .then(({ data }) => {
          if (data?.updateSpecialEvent) {
            const event = data.updateSpecialEvent?.updatedSpecialEvent;
            toast.success('Event successfully updated');
            setSpecialEvents(specialEvents.map((e) => (e.specialEventId === event.specialEventId ? event : e)));
            onClose();
          }
        })
        .catch((err) => {
          toast.error('Failed to update special event');
          console.error('Failed to update special event', err);
        });
    } else {
      // For create, include all fields
      const variables = {
        ...baseVariables,
        name: newSpecialEvent.name,
        description: newSpecialEvent.description,
        start: dayjs(newSpecialEvent.start).endOf('day').toISOString().slice(0, 10),
        end: dayjs(newSpecialEvent.end).endOf('day').toISOString().slice(0, 10),
        category: newSpecialEvent.category,
        impact: newSpecialEvent.impact,
      };

      createSpecialEvent({ variables })
        .then(({ data }) => {
          if (data?.createSpecialEvent) {
            const event = data.createSpecialEvent?.specialEvent;
            toast.success('Event successfully added to portfolio');
            setSpecialEvents([...specialEvents, event]);
            setSpecialEventId(event.specialEventId);
            nextStep();
          }
        })
        .catch((err) => {
          toast.error('Failed to create special event');
          console.error('Failed to create special event', err);
        });
    }
  };

  const handleAssignContents = () => {
    assignContents({
      variables: {
        teamId,
        portfolioId,
        specialEventIds: [specialEventId],
        assignedContentIds: includesAllContents ? [] : selectedContentIds,
        includesAllContents,
        contentRuleIds: selectedContentRules?.map((rule) => rule.contentRuleId),
      },
    })
      .then(({ data }) => {
        if (data?.assignContentsAndRulesToSpecialEvents?.specialEvents) {
          toast.success('Event successfully added to portfolio');
          handleClose();
        }
      })
      .catch((err) => {
        toast.error('Failed to assign contents to special event');
        console.error('Failed to assign contents to special event', err);
      });
  };

  const handleClose = () => {
    onClose();
    resetStep();
    resetSelectedContentRules();
    resetNewSpecialEvent();
  };

  const canSubmit =
    step === 1
      ? newSpecialEvent.name &&
        newSpecialEvent.start &&
        newSpecialEvent.end &&
        newSpecialEvent.category &&
        newSpecialEvent.impact
      : selectedContentIds.length || includesAllContents;

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={handleClose}
      paperProps={{
        style: {
          width: 'calc(100% - 218px)',
          paddingBottom: '78px',
        },
      }}
    >
      <div className="border border-b-[#eaeaea] px-6 py-5">
        <h3 className="text-xl font-medium text-neutral-900">{isEditMode ? 'Edit event' : 'Create event'}</h3>
      </div>
      <SidePanelContent className="!p-0">
        <StepperWrapper className="!w-[24%] border-r-[1px] border-[#EAEAEA] p-6">
          <VerticalStepper className="max-w-80 !p-0" current={step} steps={MANUAL_SPECIAL_EVENT_CREATION_STEPS} />
        </StepperWrapper>
        <StepWrapper>
          {step === 1 ? (
            <SpecialEventDetails />
          ) : (
            <SpecialEventAssignContents setIncludesAllContents={setIncludesAllContents} />
          )}
        </StepWrapper>
      </SidePanelContent>
      <SidePanelActions>
        {step > 1 ? (
          <Button
            variant="contained"
            style={{
              marginRight: 'auto',
            }}
            disabled={loadingAssignContents}
            onClick={previousStep}
            id="previousStepBtn"
          >
            Previous step
          </Button>
        ) : null}
        <ButtonAsync
          id="next"
          variant="contained"
          color="primary"
          disabled={!canSubmit || loadingCreate || loadingUpdate || loadingAssignContents}
          loading={loadingCreate || loadingUpdate || loadingAssignContents}
          onClick={() => {
            if (step === 1) {
              handleSaveOrUpdateEvent();
            } else {
              handleAssignContents();
            }
          }}
          style={{
            marginLeft: 'auto',
          }}
        >
          {step === 1 ? `${isEditMode ? 'Update event' : 'Add event and continue'}` : 'Assign contents'}
        </ButtonAsync>
      </SidePanelActions>
    </SidePanel>
  );
};
