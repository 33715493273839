import dayjs from 'dayjs';
import { NexoyaContentRule, NexoyaContentV2, NexoyaSpecialEvent } from '../../../types';
import { DuplicateEvent } from '../components/SpecialEvents/SpecialEventsFileUpload';

export const getSpecialEventsWithinTimeframe = ({ specialEvents, start, end }) => {
  return specialEvents?.filter((event) => isSpecialEventWithinTimeframe({ event, start, end }));
};

export const isSpecialEventWithinTimeframe = ({ event, start, end }) => {
  const eventStart = dayjs(event.start);
  const eventEnd = dayjs(event.end);

  return (
    // Event starts within optimization period
    eventStart.isBetween(dayjs(start), dayjs(end), null, '[]') ||
    // Event ends within optimization period
    eventEnd.isBetween(dayjs(start), dayjs(end), null, '[]') ||
    // Event spans across optimization period
    (eventStart.isBefore(dayjs(start)) && eventEnd.isAfter(dayjs(end)))
  );
};

export const findDuplicateEvents = (
  newEvents: Partial<NexoyaSpecialEvent>[],
  existingEvents: NexoyaSpecialEvent[],
): DuplicateEvent[] => {
  return newEvents.reduce((duplicates: DuplicateEvent[], newEvent) => {
    const duplicate = existingEvents.find(
      (existing) =>
        existing.name === newEvent.name &&
        new Date(existing.start).getTime() === new Date(newEvent.start).getTime() &&
        new Date(existing.end).getTime() === new Date(newEvent.end).getTime(),
    );
    if (duplicate) {
      duplicates.push({ existing: duplicate, new: newEvent });
    }
    return duplicates;
  }, []);
};

export const calculateImpactedContentsForEvent = ({
  contentRules,
  assignedContents,
}: {
  contentRules: NexoyaContentRule[];
  assignedContents: NexoyaContentV2[];
}) => {
  const contentRulesImpactedContents = contentRules?.reduce((acc, rule) => {
    return acc + rule.matchingDiscoveredContentsCount;
  }, 0);
  const assignedContentsCount = assignedContents?.length || 0;
  return contentRulesImpactedContents + assignedContentsCount;
};
