import React, { ReactNode, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, { Modifier } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { DayModifiers, RangeModifier } from 'react-day-picker/types/Modifiers';

import dayjs from 'dayjs';

import useDayPickerController from '../../controllers/useDayPickerController';

import { DATE_SELECTOR_DEFAULT_FORMAT, djsAnchors, getLaterDay, READABLE_FORMAT } from '../../utils/dates';

import Button from '../Button';
import ButtonAdornment from '../ButtonAdornment';
import MenuItem from '../MenuItem';
import Panel from '../Panel';
import Tooltip from '../Tooltip';
import SvgCalendarAlt from '../icons/CalendarAlt';
import SvgCaretDown from '../icons/CaretDown';
import { convertToNexoyaDateRanges } from './dateRangeConversions';
import {
  ArrowIconStyled,
  DateSelectorWrapStyled,
  MenuListStyled,
  StyledTypography,
  WrapActionsStyled,
  WrapCalendarStyled,
  WrapFormattedDatesStyled,
  WrapStyled,
} from './styles';
import { convertLocalDateToUTCIgnoringTimezone, DATE_RANGES, DateRangeOptions } from './utils';

export interface ExtendedDayModifiers extends DayModifiers {
  disabled?: boolean;
  selected?: boolean;
  end?: boolean;
}

export interface IDateRangeShort {
  from: Date;
  to: Date;
}

type DateSelectorProps = {
  dateFrom: Date;
  dateTo: Date;
  onDateChange: (props: IDateRangeShort) => void;
  panelProps?: Record<string, any>;
  hidePastQuickSelection?: boolean;
  hideFutureQuickSelection?: boolean;
  className?: string;
  style?: Record<string, unknown>;
  dateRanges?: DateRangeOptions;
  isDisabled?: boolean;
  useNexoyaDateRanges?: boolean;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  disabledRange?: RangeModifier[];
  renderDay?: (day: Date, modifiers: ExtendedDayModifiers) => ReactNode;
  applyButtonTooltipDisabledContent?: string;
  disabled?: boolean;
  minimumDaysSelection?: number;
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  format?: string;
  renderStartAdornment?: () => ReactNode;
  defaultDatePickerOpen?: boolean;
};

/**
 * @param onDateChange
 * @param dateFrom
 * @param dateTo
 * @param panelProps
 * @param hidePastQuickSelection
 * @param hideFutureQuickSelection
 * @param dateRanges
 * @param isDisabled
 * @param useNexoyaDateRanges Selector uses the local time to determine which day the Date is.
 * Nexoya uses utcStartMidnight and utcEndMidnight as the start and end of a date range.
 * A conversion wrapper can be used with useNexoyaDateRanges flag.
 * @param disableAfterDate Date() object to disable all dates after it
 * @param disableBeforeDate Date() object to disable all dates before it
 * @param renderDay Custom render function for the day
 * @param disabledRange Custom range of dates to disable instead of just having a before/after
 * @param applyButtonTooltipDisabledContent Tooltip content for the apply button when it's disabled
 * @param disabled Disable the date selector completely
 * @param minimumDaysSelection Minimum amount of days that can be selected
 * @param defaultDateFrom Default date from
 * @param defaultDateTo Default date to
 * @param format Format of the displayed date
 * @param renderStartAdornment Custom method to render the start adornment of the button that opens up the date selector
 * @param defaultDatePickerOpen Default state of the date picker
 * @param rest Rest of the props
 */
function DateSelector({
  onDateChange,
  dateFrom,
  dateTo,
  panelProps,
  hidePastQuickSelection = false,
  hideFutureQuickSelection = false,
  dateRanges = DATE_RANGES,
  isDisabled = false,
  useNexoyaDateRanges = false,
  disableAfterDate = dayjs().utc().toDate(),
  disableBeforeDate,
  renderDay,
  disabledRange,
  applyButtonTooltipDisabledContent = '',
  disabled,
  minimumDaysSelection,
  defaultDateFrom = djsAnchors.startOf7DaysAgo.toDate(),
  defaultDateTo = djsAnchors.today.toDate(),
  renderStartAdornment,
  format = DATE_SELECTOR_DEFAULT_FORMAT,
  defaultDatePickerOpen = false,
  ...rest
}: DateSelectorProps) {
  if (useNexoyaDateRanges) {
    const withConversions = convertToNexoyaDateRanges(dateFrom, dateTo, onDateChange);
    dateFrom = withConversions.dateFrom;
    dateTo = withConversions.dateTo;
    onDateChange = withConversions.onDateChange;
  }

  const { from, to, onChange, onRangeChange, reset, modifiers } = useDayPickerController({
    from: dayjs(dateFrom).isValid() ? dateFrom : defaultDateFrom,
    to: dayjs(dateTo).isValid() ? dateTo : defaultDateFrom,
  });

  const anchorEl = useRef(null);
  const tooltipRef = useRef(null);
  const [open, setOpen] = useState(defaultDatePickerOpen);
  const [isValidRange, setIsValidRange] = useState(false);
  const [disabledTooltipOpen, setDisabledTooltipOpen] = useState(false);

  if (isDisabled && open) {
    setOpen(false);
  }

  function applyDateRange() {
    setOpen(false);
    onDateChange({
      from: from.value,
      to: to.value,
    });
  }

  function discardDateRange() {
    setOpen(false);
  }

  function handleRangeChange(ev: SyntheticEvent<HTMLButtonElement>) {
    const { selection } = ev.currentTarget.dataset;
    const selectedRange = dateRanges[selection];
    if (!selectedRange) throw new Error('You have provided incorrect selection values');
    const dateRange = selectedRange.getDateRange();
    if (disableBeforeDate) dateRange.from = getLaterDay(dateRange.from, disableBeforeDate);

    onRangeChange(dateRange);
  }

  const disabledDays = useMemo(() => {
    const mods: Modifier[] = [];
    // Handle the before and after dates
    if (disableBeforeDate || disableAfterDate) {
      mods.push({
        before: disableBeforeDate ? convertLocalDateToUTCIgnoringTimezone(disableBeforeDate) : undefined,
        after: disableAfterDate ? convertLocalDateToUTCIgnoringTimezone(disableAfterDate) : undefined,
      });
    }
    // Handle the disabled ranges
    if (disabledRange && disabledRange.length) {
      disabledRange.forEach((range) => {
        if (range.from && range.to) {
          mods.push({
            from: convertLocalDateToUTCIgnoringTimezone(range.from),
            to: convertLocalDateToUTCIgnoringTimezone(range.to),
          });
        }
      });
    }
    return mods;
  }, [disableAfterDate, disableBeforeDate, disabledRange]);

  const areSelectedDatesDisabled = useMemo(() => {
    // Check if 'from' or 'to' is undefined, return true to disable selection
    if (!from.value || !to.value) {
      return true;
    }

    // Check if selected dates are within the disabled range
    if (disabledRange && disabledRange.length) {
      for (const range of disabledRange) {
        if (!range.from || !range.to) continue;

        const fromDay = dayjs(from.value);
        const toDay = dayjs(to.value);
        const rangeStart = dayjs(range.from);
        const rangeEnd = dayjs(range.to);

        // Check if there's an intersection with the disabled range
        if (
          fromDay.isBetween(rangeStart, rangeEnd, 'day', '[]') ||
          toDay.isBetween(rangeStart, rangeEnd, 'day', '[]') ||
          rangeStart.isBetween(fromDay, toDay, 'day', '[]') ||
          rangeEnd.isBetween(fromDay, toDay, 'day', '[]')
        ) {
          return true;
        }
      }
    }

    // Check against disableBeforeDate and disableAfterDate
    if (
      (disableBeforeDate && dayjs(from.value).isBefore(dayjs(disableBeforeDate), 'day')) ||
      (disableAfterDate && dayjs(to.value).isAfter(dayjs(disableAfterDate), 'day'))
    ) {
      return true;
    }

    // Validate against minimum selection
    if (
      minimumDaysSelection &&
      dayjs(to.value).startOf('day').diff(dayjs(from.value).startOf('day'), 'day') < minimumDaysSelection - 1
    ) {
      return true;
    }

    return false;
  }, [from.value, to.value, disabledRange, disableBeforeDate, disableAfterDate, minimumDaysSelection]);

  useEffect(() => {
    if (!open) {
      setDisabledTooltipOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setIsValidRange(!areSelectedDatesDisabled);
  }, [areSelectedDatesDisabled]);

  useEffect(() => {
    if (!isValidRange && open) {
      setTimeout(() => {
        setDisabledTooltipOpen(true);
      }, 500);
    }
  }, [isValidRange, open]);

  useEffect(() => {
    if (open && dateFrom && dateTo && (!dayjs(dateFrom).isSame(from.value) || !dayjs(dateTo).isSame(to.value))) {
      reset();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const datesExist = dateFrom && dateTo && dayjs(dateFrom).isValid() && dayjs(dateTo).isValid();

  return (
    <DateSelectorWrapStyled>
      <Button
        id="dateSelector"
        active={open}
        variant="contained"
        color="secondary"
        flat
        type="button"
        onClick={() => setOpen((s) => !s)}
        ref={anchorEl}
        disabled={disabled}
        className={!datesExist ? '!font-normal' : ''}
        startAdornment={
          renderStartAdornment ? (
            renderStartAdornment()
          ) : (
            <ButtonAdornment position="start">
              <SvgCalendarAlt />
            </ButtonAdornment>
          )
        }
        endAdornment={
          <ButtonAdornment position="end">
            <SvgCaretDown
              style={{
                transform: `rotate(${open ? '180' : '0'}deg)`,
              }}
            />
          </ButtonAdornment>
        }
        {...rest}
      >
        {datesExist ? `${dayjs(dateFrom).format(format)} - ${dayjs(dateTo).format(format)}` : 'Select date range'}
      </Button>
      <Panel
        open={open}
        color="dark"
        anchorEl={anchorEl.current}
        placement="bottom-end"
        style={{
          maxHeight: 500,
        }}
        popperProps={{
          style: {
            zIndex: 1301,
          },
        }}
        {...panelProps}
      >
        <WrapStyled>
          <div>
            <MenuListStyled color="dark">
              {Object.keys(dateRanges).map((dateRangeKey, index) => {
                const range = dateRanges[dateRangeKey];
                const selectedRange = range.getDateRange();

                // Compare the currently selected dates with the dates of the range
                const isSelected =
                  dayjs(from.value).isSame(selectedRange.from, 'day') &&
                  dayjs(to.value).isSame(selectedRange.to, 'day');

                if (hidePastQuickSelection && range.isPast) return <span key={`no-past-${index}`} />;
                if (hideFutureQuickSelection && !range.isPast) return <span key={`no-future-${index}`} />;

                return (
                  <MenuItem
                    key={dateRangeKey}
                    onClick={handleRangeChange}
                    data-selection={dateRangeKey}
                    selected={isSelected}
                  >
                    {range.name}
                  </MenuItem>
                );
              })}
            </MenuListStyled>
          </div>
          <WrapCalendarStyled>
            <WrapFormattedDatesStyled>
              <StyledTypography>{dayjs(modifiers.selected.from).format(READABLE_FORMAT)}</StyledTypography>
              <ArrowIconStyled />
              <StyledTypography style={{ textAlign: 'right' }}>
                {dayjs(modifiers.selected.to).format(READABLE_FORMAT)}
              </StyledTypography>
            </WrapFormattedDatesStyled>
            <DayPicker
              renderDay={renderDay}
              initialMonth={modifiers.initialMonth}
              className="NEXYCalendar"
              firstDayOfWeek={modifiers.firstDayOfWeek}
              numberOfMonths={2}
              selectedDays={modifiers.selected}
              disabledDays={disabledDays}
              modifiers={modifiers.startEnd}
              onDayClick={onChange}
              month={dayjs(modifiers.selected.to).toDate()}
            />
            <WrapActionsStyled>
              <Button variant="contained" size="small" color="dark" onClick={discardDateRange}>
                Cancel
              </Button>
              <Tooltip
                open={disabledTooltipOpen}
                placement="bottom"
                style={{ wordBreak: 'break-word', maxWidth: 350 }}
                popperProps={{
                  style: { zIndex: 3100 },
                }}
                content={!isValidRange ? applyButtonTooltipDisabledContent : ''}
              >
                <Button
                  ref={tooltipRef}
                  style={{ boxShadow: 'none', pointerEvents: 'all' }}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!isValidRange}
                  onClick={applyDateRange}
                >
                  Apply
                </Button>
              </Tooltip>
            </WrapActionsStyled>
          </WrapCalendarStyled>
        </WrapStyled>
      </Panel>
    </DateSelectorWrapStyled>
  );
}

export { DateSelector };
export type { DateSelectorProps };
